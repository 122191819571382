<template lang="pug">
TableV2(
  :headers="headers"
  :items="items.results"
  :pageCount="items.count"
  :currentPage="items.current"
  :querySearch="getData"
  :isLoading="isLoading"
)
</template>
<script>
import { mapActions } from 'vuex'
export default {
  data () {
    return {
      headers: [
        { value: 'eti_institution',
          text: this.$t('tableHeaders.eti_institution'),
          sortable: false },
        { value: 'date_transfer',
          text: this.$t('tableHeaders.date_transfer'),
          sortable: false },
        { value: 'old_institution_type',
          text: this.$t('tableHeaders.old_institution_type'),
          sortable: false },
        { value: 'new_institution_type',
          text: this.$t('tableHeaders.new_institution_type'),
          sortable: false },
        { value: 'user',
          text: this.$t('tableHeaders.user_id'),
          sortable: false }
      ],
      isLoading: true,
      items: {}
    }
  },
  methods: {
    ...mapActions(['getEtiTransfer']),
    async getData (filter) {
      this.isLoading = true
      const response = await this.getEtiTransfer(filter)
      this.items = response
      this.isLoading = false
    }
  }
}
</script>
